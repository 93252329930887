import React from "react";
import { useTranslation } from 'react-i18next';
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import DefaultMetaTags from "components/misc/DefaultMetaTags";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import macHeroScreenshotImageSrc from "images/hero-screenshot-4.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import styled from "styled-components";

export default () => {
  const { t } = useTranslation();

  const Subheading = styled.span`
  ${tw`tracking-widest font-bold`}
  color: #003580; // Apply the specific color here
`;
  const HighlightedText = styled.span`
  ${tw`text-primary-500`}
  color: #003580;
`;

  return (
    <>
    <DefaultMetaTags
        title={t('common.homepage.title')}
        description={t('common.homepage.description')}
        keywords={t('common.homepage.keywords')}
        type="website"
      />
    <title>{t('common.homepage.title')}</title>
    <AnimationRevealPage>
      <Hero roundedHeaderButton={false} />
      <Features
        subheading={<Subheading>{t('common.homepage.section2.subheading')}</Subheading>}
        heading={t('common.homepage.section2.heading')}
      />
      <FeatureWithSteps
        subheading={<Subheading>{t('common.homepage.section3.subheading')}</Subheading>}
        heading={
          <>
            {t('common.homepage.section3.heading.part1')} <HighlightedText> {t('common.homepage.section3.heading.part2')} </HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <div id="pricing">
      <Pricing
        subheading={<Subheading>{t('common.pricing.subheading')}</Subheading>}
        heading={
          <>
          {t('common.pricing.heading1')} <HighlightedText>{t('common.pricing.heading2')}.</HighlightedText>
          </>
        }
      />
      </div>
      {/* <MainFeature2
        subheading={<Subheading>{t('common.homepage.section4.subheading')}</Subheading>}
        heading={t('common.aboutUs.card3.heading')}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        primaryButtonText={t('common.homepage.section4.actionButton')}
        features={[
          {
            Icon: MoneyIcon,
            title: t('common.aboutUs.card3.cardTitle1'),
            description: t('common.aboutUs.card3.cardDescription1'),
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: t('common.aboutUs.card3.cardTitle2'),
            description: t('common.aboutUs.card3.cardDescription2'),
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      /> */}
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]} 
      /> */}
      <div id="faq"><FAQ/></div>
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
    </>
  );
}