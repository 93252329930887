import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Footer from "components/footers/FiveColumnWithInputForm.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import Modal from "react-modal";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-5 text-xl text-gray-600 text-center max-w-2xl`;

const ButtonsContainer = tw.div`mt-8 flex flex-col sm:flex-row justify-center`;
const Button = styled.a`
  ${tw`text-sm mx-2 mb-4 sm:mb-0 px-8 py-3 rounded-full font-bold transition-all duration-500 ease-in-out flex items-center justify-center`}
  ${({ primary }) => primary ? tw`bg-primary-500 text-white border border-primary-500 hover:bg-white hover:text-primary-500` : tw`bg-white text-primary-500 border border-primary-500 hover:bg-primary-500 hover:text-white`}
`;

const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  ${tw`bg-primary-500 text-white border border-primary-500 hover:bg-white hover:text-primary-500`}
`;

const Content = tw.div`mt-16`;

const Card = styled.div(({ alternate }) => [
  tw`mb-16 p-8 shadow-lg rounded-lg`,
  alternate ? tw`bg-gray-100` : tw`bg-white`
]);
const CardContent = tw.div`mb-8`;
const CardTitle = tw.h2`text-3xl font-bold mb-4 text-[#2b6cb0]`;
const CardDescription = tw.p`text-lg leading-loose text-gray-700`;

const ImageContainer = tw.div`flex justify-center mb-8 cursor-pointer`;
const Image = styled.img(tw`rounded-lg w-full max-w-full h-auto object-cover`);

const LargeHeadingTitle = styled.h1`
  ${tw`text-5xl lg:text-5xl font-extrabold text-center text-black`}
  font-size: 48px; /* Assuming Typography_displaySm__nMFje */
  font-weight: bold; /* Assuming SectionStarter_bold__KPamx */
  margin-bottom: 16px; /* Assuming SectionStarter_title__PeZnd */
  line-height: 1.2; /* Assuming general readability improvement */
`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-2 mr-2 text-lg font-bold text-gray-600 cursor-pointer`}
  background: none;
  border: none;
  &:hover {
    color: black;
  }
`;

Modal.setAppElement('#root');

export default () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const getDefaultLanguage = () => {
    const lang = i18n.language || window.localStorage.i18nextLng || 'en';
    return lang.split('-')[0]; // Only take the first part (e.g., 'en' from 'en-US')
  };

  const cards = [
    {
      imageSrc:
      getDefaultLanguage() === 'el' ? "/images/PerformanceManagement_el.png" : "/images/PerformanceManagement_en.png",
      title: t("common.features.performanceManagementPage.section1.title"),
      description: t("common.features.performanceManagementPage.section1.description"),
    },
    {
      imageSrc:
      getDefaultLanguage() === 'el' ? "/images/PerformanceManagementStats_el.png" : "/images/PerformanceManagementStats_en.png",
      title: t("common.features.performanceManagementPage.section2.title"),
      description: t("common.features.performanceManagementPage.section2.description"),
    },
    {
      imageSrc:
      getDefaultLanguage() === 'el' ? "/images/PerformanceManagementSelectProperty_el.png" : "/images/PerformanceManagementSelectProperty_en.png",
      title: t("common.features.performanceManagementPage.section3.title"),
      description: t("common.features.performanceManagementPage.section3.description"),
    },
    {
      imageSrc:
      getDefaultLanguage() === 'el' ? "/images/PerformanceManagementSelectTimePeriod_el.png" : "/images/PerformanceManagementSelectTimePeriod_en.png",
      title: t("common.features.performanceManagementPage.section4.title"),
      description: t("common.features.performanceManagementPage.section4.description"),
    },
  ];

  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
    <title>{t('common.features.performanceManagementPage.title')}</title>
    <AnimationRevealPage>
      <Header />
      <Container>
        <SingleColumn>
          <HeadingInfoContainer>
            <LargeHeadingTitle className="Typography_displaySm__nMFje SectionStarter_bold__KPamx SectionStarter_title__PeZnd">
              {t("common.features.performanceManagementPage.headingTitle")}
            </LargeHeadingTitle>
            <HeadingDescription>
              {t("common.features.performanceManagementPage.headingDescription")}
            </HeadingDescription>
            <ButtonsContainer>
              <Button href={`https://app.myrentalytics.com/auth/sign-up?lang=${currentLang}`} primary={false}>{t("common.button.freeTrial")}</Button>
              <NavLink to="/contact">
                <BuyNowButton>
                  {t("common.button.requestDemo")}
                </BuyNowButton>
              </NavLink>
            </ButtonsContainer>
          </HeadingInfoContainer>

          <Content>
            {cards.map((card, i) => (
              <Card key={i} alternate={i % 2 === 1}>
                <CardContent>
                  <CardTitle>{card.title}</CardTitle>
                  <CardDescription>{card.description}</CardDescription>
                </CardContent>
                <ImageContainer onClick={() => handleImageClick(card.imageSrc)}>
                  <Image src={card.imageSrc} alt="Main feature" style={{ maxWidth: '1321px', maxHeight: '768px' }} />
                </ImageContainer>
              </Card>
            ))}
          </Content>
        </SingleColumn>
        <SvgDotPattern1 />
        <SvgDotPattern2 />
        <SvgDotPattern3 />
        <SvgDotPattern4 />
      </Container>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <CloseButton onClick={closeModal}>X</CloseButton>
        <Image src={modalImage} alt="Enlarged feature" />
      </Modal>
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
