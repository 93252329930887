import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import logo from 'images/logo.svg'; // Adjust the path as needed

const ErrorPageWrapper = styled.div`
  ${tw`flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-900`}
`;
const Logo = styled.img`
  ${tw`w-32 mb-8`}
`;
const Title = styled.h1`
  ${tw`text-3xl font-bold`}
`;
const Message = styled.p`
  ${tw`mb-4 text-xl`}
`;
const HomeButton = styled(Link)`
  ${tw`px-8 py-3 rounded bg-primary-500 text-gray-100 font-semibold hover:bg-primary-700 transition-colors duration-300`}
`;

const SimpleError404Page = () => {
  return (
    <ErrorPageWrapper>
      <Logo src={logo} alt="MyRentalytics Logo" />
      <Title>404 - Page Not Found</Title>
      <Message>MyRentalytics</Message>
      <Message>Oops! The page you're looking for doesn't exist.</Message>
      <HomeButton to="/">Go Home</HomeButton>
    </ErrorPageWrapper>
  );
};

export default SimpleError404Page;
