import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedIn-icon.svg";

const Container = styled.div`
  ${tw`relative text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`}
  background-color: #003580; // Set the custom color
`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkA = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const StyledLink = styled(RouterLink)`
  ${tw`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300 cursor-pointer`}
`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0`;

const SocialLinksContainer = tw.div`mt-4 md:mt-0 flex space-x-4`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const AdditionalLinksContainer = tw.div`text-left mt-4 md:mt-1 space-y-2 md:space-y-0`;
const AdditionalLink = tw(StyledLink)`text-xs text-gray-400 mr-1 last:mr-0`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;


const Legal = tw.div`text-center md:text-left mt-8 md:mt-0 md:order-2`;
const ContactInfo = tw.div`text-center md:text-left mt-8 md:mt-0 md:order-2`;
const Address = tw.p`text-sm text-white`;
const Phone = tw.p`text-sm text-white`;
const Flag = tw.span`inline-block mr-2`;

export default () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>MyRentalytics</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <StyledLink id="pricing-button-footer" to="/pricing">{t('common.footer.pricing')}</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink id="faq-button-footer" to="/#faq">{t('common.footer.faq')}</StyledLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>{t('common.footer.features')}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <StyledLink id="financial-management-feature-button-footer" to="/features/financial-management">{t('common.headerNav.featuresItems.FinancialManagement')}</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink id="performance-management-feature-button-footer" to="/features/performance-management">{t('common.headerNav.featuresItems.PerformanceManagement')}</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink id="tax-and-commission-management-feature-button-footer" to="/features/tax-and-commission-management">{t('common.headerNav.featuresItems.TaxAndCommissionManagement')}</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink id="customer-management-feature-button-footer" to="/features/customer-management">{t('common.headerNav.featuresItems.CustomerManagement')}</StyledLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column> 
            <ColumnHeading>{t('common.footer.about')}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <StyledLink id="company-button-footer" to="/company">{t('common.footer.company')}</StyledLink>
              </LinkListItem>
              <LinkListItem>
                 <StyledLink id="blog-button-footer" to="/blog">{t('common.footer.blog')}</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink id="contact-us-button-footer" to="/contact">{t('common.footer.support')}</StyledLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>{t('common.footer.app')}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <LinkA id="login-button-footer" href="https://app.myrentalytics.com/auth/login">{t('common.footer.login')}</LinkA>
              </LinkListItem>
              <LinkListItem>
                <LinkA id="signup-button-top" href={`https://app.myrentalytics.com/auth/sign-up?lang=${currentLang}`}>{t('common.footer.signUp')}</LinkA>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <Legal>
            <CopywrightNotice>&copy; 2024 MyRentalytics. All Rights Reserved.</CopywrightNotice>
            <AdditionalLinksContainer>
              <AdditionalLink id="privacy-policy-link" to="/privacy-policy">{t('common.footer.privacyPolicy')} |</AdditionalLink>
              <AdditionalLink id="terms-of-service-link" to="/terms-of-service">{t('common.footer.termsOfService')} |</AdditionalLink>
              <AdditionalLink id="cookies-policy-link" to="/cookies-policy">{t('common.footer.cookiesPolicy')}</AdditionalLink>
            </AdditionalLinksContainer>
          </Legal>
          <ContactInfo>
            {t('common.location.country')} <Flag>🇬🇷</Flag>
            <Address>{t('common.location.address')}</Address>
            <Phone>+30 694 85 4444</Phone>
          </ContactInfo>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/profile.php?id=61563607641052" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@MyRentalytics" target="_blank" rel="noopener noreferrer">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/myrentalytics" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
