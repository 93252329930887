import React from "react";
import { Helmet } from "react-helmet";

const DefaultMetaTags = ({ title, description, keywords, image, url, type }) => {
  const defaultTitle = "MyRentalytics";
  const defaultDescription = "MyRentalytics platform for bookkeeping performance management and tax and commission management tied to STR property managers and owners.";
  const defaultKeywords = "myrentalytics, bookkeeping platform, performance management platform, tax and commission management platform, short term rental, str, rental, software, dashboards, budgeting, financial overview, tax and commission management, timeline, performance KPIs, key performance indicators, airbnb, booking";
  const defaultType = "website";

  const defaultUrl = url || window.location.href;

  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:url" content={defaultUrl} />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:type" content={type || defaultType} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      {image && <meta name="twitter:image" content={image} />}
      <link rel="canonical" href={defaultUrl} />
    </Helmet>
  );
};

export default DefaultMetaTags;
