import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import DefaultMetaTags from "components/misc/DefaultMetaTags";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { useTranslation } from 'react-i18next';

const Container = tw.div`flex flex-col items-center`;
const CardContainer = tw.div`w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg`;
const LocationContainer = tw.div`bg-gray-100 p-8 rounded-lg shadow-md flex flex-col items-center text-center`;
const Title = tw.h2`text-2xl font-bold text-gray-900`;
const Address = tw.p`mt-4 text-lg leading-relaxed text-gray-700`;
const Email = tw.p`mt-2 text-sm text-gray-500`;
const Phone = tw.p`mt-2 text-sm text-gray-500`;
const Pattern = tw.div`absolute top-0 right-0 w-24 h-24 text-gray-200`;

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <DefaultMetaTags
        title={t('common.contactUs.title')}
        description={t('common.contactUs.description')}
        keywords={t('common.contactUs.keywords')}
        type="website"
      />
      <title>{t('common.contactUs.title')}</title>
      <AnimationRevealPage>
        <Header />
        <ContactUsForm />
        <Container>
          <CardContainer>
            <LocationContainer>
              <Pattern>
                <SvgDotPattern />
              </Pattern>
              <Title>{t('common.location.heading')}</Title>
              <Address>{t('common.location.address')}</Address>
              <Email>info@myrentalytics.com</Email>
              <Phone>+30 694 585 4444</Phone>
            </LocationContainer>
          </CardContainer>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
