import React from "react";
import { useTranslation } from 'react-i18next';
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import DefaultMetaTags from "components/misc/DefaultMetaTags";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/ThreePlans.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SingleCol.js";

export default () => {
  const { t } = useTranslation();

  let Subheading = styled.span`
    ${tw`tracking-widest font-bold text-primary-500`}
    color: #003580; // Set the custom color
  `;
  let HighlightedText = styled.span`
  ${tw`text-primary-500`}
  color: #003580; // Set the custom color
  `;

  return (
    <>
    <DefaultMetaTags
        title={t('common.pricing.title')}
        description={t('common.pricing.description')}
        keywords={t('common.pricing.keywords')}
        type="website"
      />
    <title>{t('common.pricing.title')}</title>
    <AnimationRevealPage>
      <Header />
      <Pricing
        subheading={<Subheading>{t('common.pricing.subheading')}</Subheading>}
        heading={
          <>
          {t('common.pricing.heading1')} <HighlightedText>{t('common.pricing.heading2')}.</HighlightedText>

          </>
        }
      />
      {/* <Testimonial
        heading="Our Paying Customers"
      /> */}
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
    </>
  );
};
