import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import DefaultMetaTags from "components/misc/DefaultMetaTags";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import KostasKostalamprosImage from "images/Kostas_Kostalampros_400x400.jpg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const SubHeading = tw.h2`text-gray-800 mt-8 mb-4 text-2xl font-bold`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const AuthorSection = tw.div`mt-16 flex items-center`;
const AuthorImage = tw.img`w-20 h-20 rounded-full mr-4`;
const AuthorInfo = tw.div`flex flex-col`;
const AuthorNameAndSocialLinks = tw.div`flex items-center`;
const AuthorName = tw.div`text-lg font-semibold mr-4`;
const AuthorSocialLinks = tw.div`flex`;
const AuthorDescription = tw.div`mt-4 text-gray-800`;

const SocialButton = styled.a`
  ${tw`text-lg mr-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const WrittenBy = styled.div`
  ${tw`text-lg font-semibold mb-4 text-left w-full`} // Full width and aligned to left
`;

const BlogPostPage = ({
  title = "Blog Post Title",
  subTitle = "Blog Post Subtitle",
  author = {
    name: "Kostas Kostalampros",
    image: KostasKostalamprosImage,
    twitter: "https://x.com/KKostalampros",
  },
  content = <p>Blog post content goes here...</p>,
  metaDescription,
  metaKeywords,
  metaImage,
  metaUrl,
  metaType = "article" // Default type for blog posts
}) => {
  const defaultDescription = "Kostas is the founder of MyRentalytics.com and has a background in software engineering. He is also a short-term rental property owner. With over 15 years of combined industry knowledge, he enjoys sharing his expertise through blog posts.";

  return (
    <>
      <DefaultMetaTags
        title={title}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImage}
        url={metaUrl}
        type={metaType}
      />
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{title}</Heading>
            </HeadingRow>
            <SubHeading>{subTitle}</SubHeading>
            <Text>
              {content}
            </Text>
            <AuthorSection>
              <AuthorImage src={author.image} alt={author.name} />
              <AuthorInfo>
                <WrittenBy>Written By</WrittenBy>
                <AuthorNameAndSocialLinks>
                  <AuthorName>{author.name}</AuthorName>
                  <AuthorSocialLinks>
                    <SocialButton href={author.twitter} target="_blank" rel="noopener noreferrer">
                      <svg viewBox="0 0 24 24" fill="currentColor">
                        <path d="M24 4.56c-.88.39-1.82.65-2.82.77a4.92 4.92 0 0 0 2.16-2.71 9.86 9.86 0 0 1-3.13 1.2 4.92 4.92 0 0 0-8.38 4.48 13.95 13.95 0 0 1-10.12-5.13 4.93 4.93 0 0 0 1.53 6.57 4.9 4.9 0 0 1-2.23-.61c-.05 2.28 1.61 4.43 3.95 4.91a4.93 4.93 0 0 1-2.22.08 4.93 4.93 0 0 0 4.59 3.42A9.86 9.86 0 0 1 0 19.54 13.94 13.94 0 0 0 7.56 22c9.14 0 14.14-7.58 14.14-14.14 0-.22 0-.43-.02-.64A10.1 10.1 0 0 0 24 4.56z" />
                      </svg>
                    </SocialButton>
                  </AuthorSocialLinks>
                </AuthorNameAndSocialLinks>
                <AuthorDescription>{author.description || defaultDescription}</AuthorDescription>
              </AuthorInfo>
            </AuthorSection>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default BlogPostPage;
