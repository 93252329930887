import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const TimelineContainer = styled.div`
  ${tw`relative flex flex-col items-center mt-10`}
  &::before {
    content: '';
    ${tw`absolute left-1/2 transform -translate-x-1/2 w-px bg-gray-400 h-full`}
  }
`;
const TimelineItem = styled.div`
  ${tw`flex flex-col items-center mb-10 relative`}
  &:last-child {
    ${tw`mb-0`}
  }
`;

const TimelineImageWrapper = styled.div`
  ${tw`flex space-x-4`}
`;

const TimelineImageContainer = styled.div`
  ${tw`rounded-full bg-gray-300 border-2 border-gray-400 mb-2 overflow-hidden flex items-center justify-center cursor-pointer space-x-2`}
  width: 400px; /* Adjust the width as needed */
  height: 220px; /* Adjust the height as needed */
  @media (max-width: 768px) {
    width: 300px; /* Adjust the width for smaller screens */
    height: 165px; /* Adjust the height for smaller screens */
  }
  @media (max-width: 600px) {
    width: 170px; /* Adjust the width for mobile screens */
    height: 110px; /* Adjust the height for mobile screens */
  }
`;

const TimelineLine = styled.div`
  ${tw`w-px bg-gray-400 flex-grow`}
`;
const TimelineTitle = tw.h5`mt-4 leading-none text-xl font-semibold text-center`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const ModalOverlay = styled.div`
  ${tw`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center`}
  z-index: 50;
`;

const ModalContent = styled.div`
  ${tw`relative bg-white rounded-lg overflow-hidden`}
  max-width: 80%; /* Adjust the max-width to make the modal content smaller */
  max-height: 80%; /* Adjust the max-height to make the modal content smaller */
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-2 mr-2 text-black`}
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalImage = styled.img`
  ${tw`max-w-full max-h-full`}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", }) => {
  const { t, i18n } = useTranslation();

  const getDefaultLanguage = () => {
    const lang = i18n.language || window.localStorage.i18nextLng || 'en';
    return lang.split('-')[0]; // Only take the first part (e.g., 'en' from 'en-US')
  };

  const description = t('common.homepage.section2.description');

  const defaultCards = [
    {
      imageSrc: getDefaultLanguage() === 'el' ? "/images/CreateTransaction_el.png" : "/images/CreateTransaction_en.png",
      imageSrc2: getDefaultLanguage() === 'el' ? "/images/CreateTransactionsBulk_el.png" : "/images/CreateTransactionsBulk_en.png",
      title: t('common.homepage.section2.card1.title'),
    },
    { 
      imageSrc: getDefaultLanguage() === 'el' ? "/images/TaxAndCommissionsConfigAutomatic_el.png" : "/images/TaxAndCommissionsConfigManual_en.png",
      title: t('common.homepage.section2.card2.title'),
    },
    { 
      imageSrc: getDefaultLanguage() === 'el' ? "/images/financialManagement_el.png" : "/images/financialManagement_en.png",
      title: t('common.homepage.section2.card3.title'),
    },
    { 
      imageSrc: getDefaultLanguage() === 'el' ? "/images/PerformanceManagement_el.png" : "/images/PerformanceManagement_en.png",
      title: t('common.homepage.section2.card4.title'),
    },
    { 
      imageSrc: getDefaultLanguage() === 'el' ? "/images/TaxAndCommissionsTable_el.png" : "/images/TaxAndCommissionsTable_en.png",
      title: t('common.homepage.section2.card5.title'),
    },
    {
      imageSrc: getDefaultLanguage() === 'el' ? "/images/CustomerProfile_el.png" : "/images/CustomerProfile_en.png",
      title: t('common.homepage.section2.card6.title'),
    }
  ];

  if (!cards) cards = defaultCards;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState("");

  const openModal = (imageSrc) => {
    setCurrentImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImageSrc("");
  };

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer>
          <TimelineContainer>
            {cards.map((card, i) => (
              <TimelineItem key={i}>
                <TimelineImageWrapper>
                  <TimelineImageContainer onClick={() => openModal(card.imageSrc || defaultCardImage)}>
                    <img src={card.imageSrc || defaultCardImage} alt="" style={{ maxWidth: '100%', maxHeight: '130%' }}/>
                  </TimelineImageContainer>
                  {card.imageSrc2 && (
                    <TimelineImageContainer onClick={() => openModal(card.imageSrc2 || defaultCardImage)}>
                      <img src={card.imageSrc2} alt="" style={{ maxWidth: '100%', maxHeight: '130%' }}/>
                    </TimelineImageContainer>
                  )}
                </TimelineImageWrapper>
                <TimelineTitle>{card.title || "Title"}</TimelineTitle>
                {i < cards.length - 1 && <TimelineLine />}
              </TimelineItem>
            ))}
          </TimelineContainer>
        </VerticalSpacer>
      </ThreeColumnContainer>
      <DecoratorBlob />
      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            <ModalImage src={currentImageSrc} alt="Full Size" />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};
