import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import GlobalStyles from 'styles/GlobalStyles';
import LanguageValidator from "./LanguageValidator";
import BlogRoutes from "components/blogs/BlogRoutes";

/* Inner Pages */
import PricingPage from "./pages/Pricing";
import AboutUsPage from "./pages/AboutUs";
import ContactUsPage from "pages/ContactUs";
import FinancialManagementPage from "pages/FinancialManagementPage";
import PerformanceManagementPage from "pages/PerformanceManagementPage";
import TaxAndCommissionManagementPage from "pages/TaxAndCommissionManagementPage";
import CustomerManagementPage from "pages/CustomerManagementPage";
import PrivacyPolicyPage from "pages/PrivacyPolicy";
import TermsOfServicePage from "pages/TermsOfService";
import CookiesPolicyPage from "pages/CookiesPolicyPage";
import MainLandingPage from "homepage";
import Error404Page from "./pages/Error404Page";
import './i18n/i18n';

const App = () => {
  const { i18n } = useTranslation();

  const getDefaultLanguage = () => {
    const lang = i18n.language || window.localStorage.i18nextLng || 'en';
    return lang.split('-')[0]; // Only take the first part (e.g., 'en' from 'en-US')
  };

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route
            path="/:lng/*"
            element={
              <LanguageValidator>
                <Routes>
                  <Route path="pricing" element={<PricingPage />} />
                  <Route path="company" element={<AboutUsPage />} />
                  <Route path="features/financial-management" element={<FinancialManagementPage />} />
                  <Route path="features/performance-management" element={<PerformanceManagementPage />} />
                  <Route path="features/tax-and-commission-management" element={<TaxAndCommissionManagementPage />} />
                  <Route path="features/customer-management" element={<CustomerManagementPage />} />
                  <Route path="contact" element={<ContactUsPage />} />
                  <Route path="blog/*" element={<BlogRoutes />} />
                  <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                  <Route path="terms-of-service" element={<TermsOfServicePage />} />
                  <Route path="cookies-policy" element={<CookiesPolicyPage />} />
                  <Route path="" element={<MainLandingPage />} />
                  <Route path="*" element={<Error404Page />} />
                </Routes>
              </LanguageValidator>
            }
          />
          <Route path="/" element={<Navigate to={`/${getDefaultLanguage()}/`} replace />} />
          <Route path="/pricing" element={<Navigate to={`/${getDefaultLanguage()}/pricing`} replace />} />
          <Route path="/company" element={<Navigate to={`/${getDefaultLanguage()}/company`} replace />} />
          <Route path="/features/financial-management" element={<Navigate to={`/${getDefaultLanguage()}/features/financial-management`} replace />} />
          <Route path="/features/performance-management" element={<Navigate to={`/${getDefaultLanguage()}/features/performance-management`} replace />} />
          <Route path="/features/tax-and-commission-management" element={<Navigate to={`/${getDefaultLanguage()}/features/tax-and-commission-management`} replace />} />
          <Route path="/features/customer-management" element={<Navigate to={`/${getDefaultLanguage()}/features/customer-management`} replace />} />
          <Route path="/contact" element={<Navigate to={`/${getDefaultLanguage()}/contact`} replace />} />
          <Route path="/blog/*" element={<Navigate to={`/${getDefaultLanguage()}/blog`} replace />} />
          <Route path="/privacy-policy" element={<Navigate to={`/${getDefaultLanguage()}/privacy-policy`} replace />} />
          <Route path="/terms-of-service" element={<Navigate to={`/${getDefaultLanguage()}/terms-of-service`} replace />} />
          <Route path="/cookies-policy" element={<Navigate to={`/${getDefaultLanguage()}/cookies-policy`} replace />} />
          <Route path="*" element={<Error404Page />} /> {/* Catch-all route */}
        </Routes>
      </Router>
    </>
  );
};

export default App;
