import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import ReactCountryFlag from "react-country-flag";
import { ContactsTwoTone, BankTwoTone, DashboardTwoTone, DollarTwoTone } from '@ant-design/icons';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-2xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const StyledLink = styled(RouterNavLink)`
 ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}

 &.active {
   border-bottom-color: #003580; /* Or any color you prefer for the underline */
 }
`;

export const StyledLinkA = styled.a`
  ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 cursor-pointer`}
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const StyledPrimaryLink = styled(StyledLink)`
  ${tw`lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0`}
`;

export const StyledPrimaryLinkA = styled(StyledLinkA)`
  ${tw`lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0`}

  /* Change the background color to #003580 */
  background-color: #003580;

  &:hover, &:focus {
    background-color: #002354; /* Darker shade for hover or focus */
  }
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const LanguageSwitchContainer = styled.div`
  ${tw`flex items-center ml-10 mr-16`}
  margin-right: auto; /* Pushes the language buttons to the left and creates space from the right */
`;

const LanguageButton = styled.button`
  ${tw`mx-2 px-2 py-1 rounded bg-white border border-gray-300 text-gray-700`}
  ${({ isActive }) => isActive && tw`bg-blue-500 text-white border-blue-500`}
`;

const DropdownContainer = styled.div`
  ${tw`relative`}
`;

const DropdownMenu = styled.div`
  ${tw`absolute mt-2 py-2 bg-white rounded-md shadow-xl z-20`}
  width: 500px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  @media (max-width: 1020px) { /* Adjust for mobile screens */
    width: 420px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const DropdownItem = styled(RouterNavLink)`
  ${tw`flex items-start px-6 py-4 text-gray-700 font-semibold border-b border-gray-100 hover:bg-gray-100 hover:text-primary-500`}
  white-space: nowrap;

  ${({ isGreek }) => isGreek && css`
    ${tw`text-sm`} /* Make font smaller for Greek language */
  `}

  @media (max-width: 1020px) { /* Adjust for mobile screens */
    ${tw`px-4 py-2 text-sm`}
  }
  
  img {
    ${tw`w-8 h-8 mr-4`}

    @media (max-width: 640px) { /* Adjust for mobile screens */
      ${tw`w-6 h-6 mr-3`}
    }
  }

  div {
    ${tw`ml-4`}
  }

  p {
    ${tw`text-sm text-gray-500 mt-1`} /* Additional styles for description */
  }
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "xl", showLogoText = true, LogoClickable = true }) => {
  const { lng } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const defaultLinks = [
    <NavLinks key={1} tw="flex items-center space-x-6">
      <DropdownContainer onMouseEnter={handleMouseEnter} onClick={handleMouseEnter} css={isDropdownOpen && tw`border-b-0`}>
        <StyledLink as="div">
          {t('common.headerNav.features')}
        </StyledLink>
        <DropdownMenu isVisible={isDropdownOpen} onMouseLeave={handleMouseLeave}>
          <DropdownItem to="/features/financial-management">
            <DollarTwoTone style={{ fontSize: '36px' }} />
            <div>
              {t('common.headerNav.featuresItems.FinancialManagement')}
              <p>{t('common.headerNav.featuresItems.FinancialManagementDesc')}</p>
            </div>
          </DropdownItem>
          <DropdownItem to="/features/performance-management">
            <DashboardTwoTone style={{ fontSize: '36px' }} />
            <div>
              {t('common.headerNav.featuresItems.PerformanceManagement')}
              <p>{t('common.headerNav.featuresItems.PerformanceManagementDesc')}</p>
            </div>
          </DropdownItem>
          <DropdownItem to="/features/tax-and-commission-management">
            <BankTwoTone style={{ fontSize: '36px' }} />
            <div>
              {t('common.headerNav.featuresItems.TaxAndCommissionManagement')}
              <p>{t('common.headerNav.featuresItems.TaxAndCommissionManagementDesc')}</p>
            </div>
          </DropdownItem>
          <DropdownItem to="/features/customer-management">
            <ContactsTwoTone style={{ fontSize: '36px' }} />
            <div>
              {t('common.headerNav.featuresItems.CustomerManagement')}
              <p>{t('common.headerNav.featuresItems.CustomerManagementDesc')}</p>
            </div>
          </DropdownItem>
        </DropdownMenu>
  </DropdownContainer>
      <StyledLink id="pricing-button-top" to="/pricing">{t('common.headerNav.pricing')}</StyledLink>
      <StyledLink id="contact-us-button-top" to="/contact">{t('common.headerNav.contactUs')}</StyledLink>
      <StyledLink id="blog-button-top" to="/blog">{t('common.headerNav.blog')}</StyledLink>
      <StyledLink id="company-button-top" to="/company">{t('common.headerNav.company')}</StyledLink>
      <StyledLinkA id="login-button-top" href="https://app.myrentalytics.com/auth/login" tw="lg:ml-12!">
      {t('common.headerNav.login')}
      </StyledLinkA>
      <StyledPrimaryLinkA id="signup-button-top" css={roundedHeaderButton && tw`rounded-full`} href={`https://app.myrentalytics.com/auth/sign-up?lang=${currentLang}`}>{t('common.headerNav.signUp')}</StyledPrimaryLinkA>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = LogoClickable ? (
    <LogoLink as={Link} to="/">
      <img src={logo} alt="logo" />
      {showLogoText && "MyRentalytics"}
    </LogoLink>
  ) : (
    <LogoLink as="div">
      <img src={logo} alt="logo" />
      {showLogoText && "MyRentalytics"}
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    navigate(`/${language}/`);
  };

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        <LanguageSwitchContainer>
          <LanguageButton id="lang_en-button-top" onClick={() => {changeLanguage('en');}} isActive={lng === 'en'}>
            <ReactCountryFlag svg countryCode="GB" />
          </LanguageButton>
          <LanguageButton id="lang_el-button-top" onClick={() => {changeLanguage('el');}} isActive={lng === 'el'}>
            <ReactCountryFlag svg countryCode="GR" />
          </LanguageButton>
        </LanguageSwitchContainer>
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <LanguageSwitchContainer>
          <LanguageButton id="lang_en-button-top" onClick={() => {changeLanguage('en');}} isActive={lng === 'en'}>
            <ReactCountryFlag svg countryCode="GB" />
          </LanguageButton>
          <LanguageButton id="lang_el-button-top" onClick={() => {changeLanguage('el');}} isActive={lng === 'el'}>
            <ReactCountryFlag svg countryCode="GR" />
          </LanguageButton>
        </LanguageSwitchContainer>
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
