import React from "react";
import { useTranslation } from 'react-i18next';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import DefaultMetaTags from "components/misc/DefaultMetaTags";
import { SectionHeading } from "components/misc/Headings.js";
import Features from "components/features/ThreeColSimple.js";
import ShieldIconImage from "images/shield-icon.svg";
import AboutUsImage from "images/marvin-meyer-SYTO3xs06fU-unsplash.jpg";
import OurVisionImage from "images/mika-baumeister-Y_LgXwQEx2c-unsplash.jpg";
import KostasKostalamprosImage from "images/Kostas_Kostalampros_400x400.jpg";
import { ReactComponent as LinkedInIcon } from "images/linkedIn-icon.svg";

const TeamContainer = tw.div`mt-20`;
const TeamHeading = tw(SectionHeading)`text-center text-4xl`;
const TeamMembers = tw.div`flex flex-wrap justify-center mt-12`;
const TeamMember = tw.div`w-full md:w-1/3 lg:w-1/4 px-6 mb-12 text-center`;
const TeamMemberImage = styled.div`
  ${tw`w-32 h-32 bg-gray-300 rounded-full mx-auto`}
  background-image: url(${props => props.imageSrc});
  background-size: cover;
  background-position: center;
`;
const TeamMemberName = tw.h4`mt-4 text-lg font-bold flex items-center justify-center`;
const TeamMemberTitle = tw.h5`mt-2 text-xl font-bold`;
const TeamMemberDescription = tw.p`mt-2 text-sm text-gray-600`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-200 ml-2 inline-block text-blue-500 hover:text-blue-700 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default () => {
  const { t } = useTranslation();
  const teamMembers = [
    {
      name: t("common.teamMembers.KostasKostalampros.name"),
      title: t("common.teamMembers.KostasKostalampros.title"),
      description: t("common.teamMembers.KostasKostalampros.description"),
      imageSrc: KostasKostalamprosImage,
      linkedinUrl: "https://www.linkedin.com/in/kostas-kostalampros"
    }
  ];

  return (
    <>
      <DefaultMetaTags
        title={t('common.aboutUs.title')}
        description={t('common.aboutUs.description')}
        keywords={t('common.aboutUs.keywords')}
        type="website"
      />
      <title>{t('common.aboutUs.title')}</title>
      <AnimationRevealPage>
        <Header />
        <MainFeature1
          heading={t('common.aboutUs.card1.heading')}
          description={t('common.aboutUs.card1.description')}
          imageSrc={AboutUsImage}
        />
        <MainFeature1
          heading={t('common.aboutUs.card2.heading')}
          description={t('common.aboutUs.card2.description')}
          buttonRounded={false}
          primaryButtonText={t('common.aboutUs.card2.primaryButtonText')}
          imageSrc={OurVisionImage}
          textOnLeft={false}
        />
        <Features
          heading={t('common.aboutUs.card3.heading')}
          cards={[
            {
              imageSrc: ShieldIconImage,
              title: t('common.aboutUs.card3.cardTitle2'),
              description: t('common.aboutUs.card3.cardDescription2'),
            },
            {
              imageSrc: ShieldIconImage,
              title: t('common.aboutUs.card3.cardTitle1'),
              description: t('common.aboutUs.card3.cardDescription1'),
            },
          ]}
          linkText=""
        />

        <TeamContainer>
          <TeamHeading>{t("common.aboutUs.meetOurTeam")}</TeamHeading>
          <TeamMembers>
            {teamMembers.map((member, index) => (
              <TeamMember key={index}>
                <TeamMemberImage imageSrc={member.imageSrc} />
                <TeamMemberName>
                  {member.name}
                  <SocialLink href={member.linkedinUrl} target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon />
                  </SocialLink>
                </TeamMemberName>
                <TeamMemberTitle>{member.title}</TeamMemberTitle>
                <TeamMemberDescription>{member.description}</TeamMemberDescription>
              </TeamMember>
            ))}
          </TeamMembers>
        </TeamContainer>

        <Footer />
      </AnimationRevealPage>
    </>
  );
};
