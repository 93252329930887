import React, { useState, useRef, useEffect } from "react";
import tw from "twin.macro";
import styled, { createGlobalStyle } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { GlobalStyles as BaseStyles } from "twin.macro";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = styled(SubheadingBase)`
  ${tw`text-center md:text-left`}
  color: #003580; // Set the custom color
`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-2xl sm:text-3xl lg:text-3xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

// Styled component for success and error messages
const Message = styled.div(({ isSuccess }) => [
  isSuccess ? tw`bg-green-100 text-green-700` : tw`bg-red-100 text-red-700`,
  tw`p-4 rounded mt-4 text-sm`
]);

export default ({
  description = "",
  textOnLeft = true,
}) => {
  const formRef = useRef(); // Create a ref for the form
  const [message, setMessage] = useState(''); // State to hold the message status
  const [isSuccess, setIsSuccess] = useState(true); // State to track if message is success or error
  const { t, i18n } = useTranslation();

  const subheading = t('common.contactUs.subheading');
  const heading = t('common.contactUs.heading');
  const submitButtonText = t('common.contactUs.sendButton');

  useEffect(() => {
    document.documentElement.lang = i18n.language; // Set the lang attribute dynamically
  }, [i18n.language]);

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    emailjs.sendForm('service_hha3fft', 'template_tdewp5f', formRef.current)
      .then((result) => {
        console.log('Email successfully sent!', result.text);
        setMessage(t('common.contactUs.formSuccessMessage')); // Set success message
        setIsSuccess(true); // Set success state
        e.target.reset(); // Optionally reset the form
      }, (error) => {
        console.log('Failed to send email:', error.text);
        setMessage(t('common.contactUs.formErrorMessage')); // Set failure message
        setIsSuccess(false); // Set error state
      });
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form ref={formRef} onSubmit={sendEmail}>
              <Input type="email" name="email" placeholder={t('common.contactUs.emailAddress')} />
              <Input type="text" name="name" placeholder={t('common.contactUs.fullName')} />
              <Input type="text" name="subject" placeholder={t('common.contactUs.subject')} />
              <Textarea name="message" placeholder={t('common.contactUs.message')} />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
            {message && <Message isSuccess={isSuccess}>{message}</Message>} {/* Styled message */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
