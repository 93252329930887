import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const validLanguages = ['en', 'el'];

const LanguageValidator = ({ children }) => {
  const { lng, '*': restOfPath } = useParams();

  if (!validLanguages.includes(lng)) {
    return <Navigate to={`/en/${restOfPath}`} replace />;
  }

  return children;
};

export default LanguageValidator;
