import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Cookies Policy" }) => {
  const { i18n } = useTranslation();

  const getDefaultLanguage = () => {
    const lang = i18n.language || window.localStorage.i18nextLng || 'en';
    return lang.split('-')[0]; // Only take the first part (e.g., 'en' from 'en-US')
  };

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <Text>
            {getDefaultLanguage() === "en" ? (
              <>
                <title>Cookies Policy | MyRentalytics</title>
                <h1>{headingText}</h1>
                <br />
                <p><i>Last updated: June 1, 2024</i></p>
                <br />

                <div>
                  <p>The company Konstantinos Kostalampros hereby informs the users of the website <a href="https://www.myrentalytics.com/">https://www.myrentalytics.com/</a> (hereinafter the “Website”) about the use of cookies through the website.</p>
                  <p>This policy applies only to the website <a href="https://www.myrentalytics.com/">https://www.myrentalytics.com/</a> and not to any other websites that the user might consult or access through links that the Website may include.</p>
                  <p>Cookies are text files that contain bundles of information that are stored on the directory of the web browser of a user’s computer or mobile device (e.g. notebook, tablet, smartphone, etc.) every time a website is accessed online through the browser. Whenever this website is visited again subsequently, the browser sends these cookies to the website that originated them or to another website. The cookies allow these websites to memorise some information so that the person visiting the website can explore it quickly and easily. A cookie cannot bring up any other data from the user’s hard drive, nor can it transmit computer viruses or obtain email addresses. Every cookie is unique to the user’s browser.</p>
                  <p>Similar technologies, e.g. web beacons or transparent GIFs, can be used to gather information on a user’s behavior and his/her use of the services. In this Cookie Policy, all such technologies will be referred to as “cookies”.</p>
                  <p>There are various types of cookies, some devised to make use of the website more efficient and to improve the browsing experience of the user, others enabling particular functions to operate.</p>
                  <p>More information on cookies can be found at the following URL sites:</p>
                  <ul>
                    <li><a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a></li>
                  </ul>

                  <h2>Types of cookies used</h2>

                  <h3>Technical cookies</h3>
                  <p>Technical cookies are used to make certain sections of the Website function correctly. The Website uses only technical cookies, those that are “strictly necessary to transmit a communication or provide a specific service as requested by a subscriber or user” (Article 4 par. 5 of Law 3471/2006).</p>
                  <p>They can be divided into browser cookies and session cookies, which ensure that the Website functions properly:</p>
                  <ul>
                    <li>analytics cookies, which are technical cookies when they are used to measure and analyse overall user numbers and behaviour on a particular website;</li>
                    <li>functionality cookies, which make it possible to tailor the website according to various choices made by the user (e.g. language, etc), in order to provide improved service.</li>
                  </ul>
                  <p>No prior consent from users is required for the installation of these types of cookies, although the obligation to provide information about them still stands, in accordance with Article 4 par. 5 of Law 3471/2006.</p>
                  <p>The acquisition and processing of data obtained by the use of technical cookies is necessary if the Website is to function properly. If a user objects to the use of these technical cookies, he/she will not be able to access and view the Website properly.</p>

                  <h3>List of the technical and analytics cookies used by the Website:</h3>
                  <br />
                  <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <thead style={{ backgroundColor: "#f2f2f2" }}>
                      <tr>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Cookie</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Purpose</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Retention Period</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>More information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Analytics</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>_ga_*</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}> Google Analytics sets this cookie to store and count page views</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>2 years</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">More info</a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Analytics</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>_ga</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}> Google Analytics sets this cookie to calculate visitor, session and campaign data and track Site usage for the Site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>2 years</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          <a href="https://business.safety.google/adscookies/?sjid=17724917297417988176-EU">More info</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <br />

                  <h2>How to manage cookies by configuring the browser</h2>
                  <p>Some users may not consent to the storage of information collected through cookies by their computer. Each browser includes detailed instructions regarding their policy for the use of cookies.</p>
                  <p>If you do not wish to receive cookies, you may modify the settings of your browser so as to be warned, when cookies are being sent, to deactivate all or some of the cookies used by the Website or to delete cookies already installed by the Website.</p>
                  <p>The majority of the Website’s functions shall operate without the use of cookies. However, if you deactivate cookies, you shall not have access to certain services and features of the Website.</p>
                  <p>Here are the pages of browser suppliers that give detailed instructions on how to set your privacy preferences according to the browser used:</p>
                  <ul>
                    <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox: Enable and disable cookies</a></li>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome: Manage cookies and other website data</a></li>
                    <li><a href="https://support.apple.com/kb/PH19214?locale=en_US">Safari 8 (Yosemite): Manage cookies and other website data</a></li>
                    <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer: Enable and disable cookies</a></li>
                    <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera: Cookies</a></li>
                    <li><a href="https://support.apple.com/en-us/HT201265">Safari iOS (mobile): Safari web setting on your iPhone, iPad or iPod touch</a></li>
                  </ul>
                  <p>If you wish further information regarding the Website’s cookies please contact us at [INSERT THE PERTINENT CONTACT DETAILS].</p>
                </div>


                
              </>
              
            ) : (
              <>
                <title>Πολιτική Cookies | MyRentalytics</title>
                <h1>Πολιτική Cookies</h1>
                <br />
                <p><i>Τελευταία ενημέρωση: 1 Ιουνίου 2024</i></p>
                <br />

                <div>
                  <p>
                    Η ιστοσελίδα της εταιρείας https://www.myrentalytics.com (εφεξής η «Ιστοσελίδα») χρησιμοποιεί
                    cookies για να αποθηκεύσει πληροφορίες στον υπολογιστή σας. Η παρούσα
                    πολιτική χρήσης εξηγεί τον τρόπο με τον οποίο η Ιστοσελίδα χρησιμοποιεί
                    τα cookies. Με την πλοήγησή σας στην Ιστοσελίδα συμφωνείτε και
                    αποδέχεστε τη χρήση cookies στον υπολογιστή σας, σύμφωνα με τους όρους
                    της παρούσας πολιτικής χρήσης. Εάν δεν επιθυμείτε να λαμβάνετε cookies
                    από την Ιστοσελίδα, παρακαλούμε είτε να προβείτε στις κατάλληλες
                    ρυθμίσεις (όπως παρατίθενται κατωτέρω) ή να απέχετε από τη χρήση της
                    Ιστοσελίδας.
                  </p>

                  <h2>Τι είναι τα cookies;</h2>
                  <p>
                    Τα «cookies» είναι μικρά αρχεία κειμένου με πληροφορίες που μια
                    ιστοσελίδα (συγκεκριμένα ο διακομιστής ιστού – web server) αποθηκεύει
                    στον υπολογιστή ενός χρήστη, χωρίς είναι δυνατή η γνώση οποιουδήποτε
                    εγγράφου ή αρχείου από τον υπολογιστή του χρήστη. Τα cookies περιέχουν
                    κάποιες πληροφορίες, όπως το όνομα μιας ιστοσελίδας και ορισμένα ψηφία
                    και αριθμούς. Κάθε φορά που ο χρήστης συνδέεται σε μια ιστοσελίδα, η
                    τελευταία ανακτά τις εν λόγω πληροφορίες και προσφέρει στο χρήστη
                    σχετικές με αυτές υπηρεσίες. Χαρακτηριστικό παράδειγμα τέτοιων
                    πληροφοριών είναι οι προτιμήσεις του χρήστη σε μια ιστοσελίδα, όπως
                    αυτές δηλώνονται από τις επιλογές που κάνει ο χρήστης στη συγκεκριμένη
                    ιστοσελίδα (π.χ. επιλογή συγκεκριμένων «κουμπιών», αναζητήσεων,
                    διαφημίσεων, κλπ).
                  </p>

                  <h2>Τι cookies χρησιμοποιεί η Ιστοσελίδα;</h2>
                  <h3>Είδη cookies που χρησιμοποιούνται:</h3>
                  <h4>Τεχνικά cookies (technical cookies)</h4>
                  <p>
                    Τα τεχνικά cookies χρησιμοποιούνται για την σωστή λειτουργία ορισμένων
                    λειτουργιών της Ιστοσελίδας. Η Ιστοσελίδα χρησιμοποιεί τα τεχνικά
                    cookies, αποκλειστικός σκοπός των οποίων είναι η διενέργεια της
                    διαβίβασης μίας επικοινωνίας ή τα οποία είναι αναγκαία για την παροχή
                    συγκεκριμένης υπηρεσίας, την οποία έχει ζητήσει ρητά ο χρήστης ή ο
                    συνδρομητής (Άρθρο 4 παρ. 5 του Ν. 3471/2006).
                  </p>
                  <p>
                    Διακρίνονται σε cookies του φυλλομετρητή (browser cookies) και cookies
                    της σύνδεσης (session cookies), τα οποία διασφαλίζουν ότι η Ιστοσελίδα
                    λειτουργεί ορθά:
                  </p>
                  <ul>
                    <li>
                      Cookies ανάλυσης, τα οποία είναι τεχνικά cookies, όταν χρησιμοποιούνται
                      προκειμένου να μετρήσουν και να αναλύσουν συνολικά των αριθμό των
                      χρηστών και τη συμπεριφορά στη συγκεκριμένη ιστοσελίδα,
                    </li>
                    <li>
                      Cookies λειτουργικότητας, τα οποία επιτρέπουν την παραμετροποίηση της
                      ιστοσελίδας σύμφωνα με διάφορες επιλογές του χρήστη (π.χ. γλώσσα κοκ),
                      για την παροχή βελτιωμένων υπηρεσιών.
                    </li>
                  </ul>
                  <p>
                    Η συλλογή και επεξεργασία πληροφοριών μέσω της χρήσης τεχνικών cookies
                    είναι απαραίτητη για την ορθή λειτουργία της Ιστοσελίδας. Σε περίπτωση
                    που κάποιος χρήστης αντιτίθεται στη χρήση τέτοιου είδους τεχνικών
                    cookies (technical cookies) δε θα μπορεί να επισκεφθεί και να δει την
                    Ιστοσελίδα σωστά.
                  </p>

                  <h3>Λίστα των τεχνικών και analytics cookies που χρησιμοποιούνται από την Ιστοσελίδα:</h3>
                  <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <thead style={{ backgroundColor: "#f2f2f2" }}>
                      <tr>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Cookie</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Τύπος</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Περιγραφή</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>Διάρκεια Τήρησης</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Analytics</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>_ga_*</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}> Το Google Analytics ρυθμίζει αυτό το cookie για αποθήκευση και μέτρηση προβολών σελίδας</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>2 χρόνια</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Analytics</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>_ga</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}> Το Google Analytics ρυθμίζει αυτό το cookie για να υπολογίζει δεδομένα επισκεπτών, περιόδου σύνδεσης και καμπάνιας και να παρακολουθεί τη χρήση του ιστότοπου για την αναφορά αναλυτικών στοιχείων του ιστότοπου. Το cookie αποθηκεύει πληροφορίες ανώνυμα και εκχωρεί έναν αριθμό που δημιουργείται τυχαία για να αναγνωρίζει μοναδικούς επισκέπτες</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>2 χρόνια</td>
                      </tr>
                    </tbody>
                  </table>

                  <p>
                    Πέρα από τα τεχνικά cookies (technical cookies), ο γενικός κανόνας που
                    διέπει τη χρήση δικτύου ηλεκτρονικών επικοινωνιών είναι ότι η απόκτηση
                    πρόσβασης σε ήδη αποθηκευμένες πληροφορίες στον τερματικό εξοπλισμό
                    συνδρομητή προϋποθέτει την προηγούμενη συγκατάθεση του χρήστη κατόπιν
                    σαφούς και εκτενούς ενημέρωσης, η συγκατάθεση δηλαδή αυτή θα πρέπει να
                    δίνεται opt-in και όχι opt-out.
                  </p>
                  <p>
                    Συνεπώς, τα cookies τα οποία δεν αποτελούν «τεχνικά» cookies και τα οποία
                    είναι πιο σημαντικά για την ιδιωτικότητα των χρηστών, π.χ. αυτά που
                    χρησιμοποιούνται για την δημιουργία προφίλ για τον χρήστη, μπορούν να
                    εγκατασταθούν στον τερματικό εξοπλισμό του χρήστη, εφόσον ο χρήστης έχει
                    ενημερωθεί σαφώς και εκτενώς και έχει δώσει εγκύρως τη συγκατάθεσή του.
                  </p>

                  <h2>Πως μπορώ να αναθεωρήσω τις ρυθμίσεις περί λειτουργίας cookies στο browser;</h2>
                  <p>
                    Ενδέχεται ορισμένοι χρήστες να μη συμφωνούν με την αποθήκευση των
                    πληροφοριών που συλλέγονται από τα cookies στον υπολογιστή τους. Κάθε
                    φυλλομετρητής ιστοσελίδων (web browser) παρέχει σαφείς οδηγίες περί
                    πολιτικής χρήσης των cookies.
                  </p>
                  <p>
                    Αν δεν επιθυμείτε να λαμβάνετε cookies, μπορείτε να τροποποιήσετε τις
                    ρυθμίσεις του web browser σας έτσι ώστε να προειδοποιείστε όταν
                    αποστέλλονται cookies, να απενεργοποιείτε όλα ή μερικά από τα cookies που
                    χρησιμοποιεί η Iστοσελίδα ή και να διαγράφετε cookies που έχουν ήδη
                    εγκατασταθεί στον υπολογιστή σας.
                  </p>
                  <p>
                    Οι περισσότερες υπηρεσίες της Ιστοσελίδας λειτουργούν χωρίς τη χρήση
                    cookies. Ωστόσο, εάν απενεργοποιήσετε τα cookies, ενδέχεται να μην έχετε
                    πρόσβαση σε ορισμένες υπηρεσίες και χαρακτηριστικά της Ιστοσελίδας.
                  </p>
                  <p>
                    Ακολούθως παρατίθενται παραπομπές των βασικότερων web browser οι οποίες
                    αφορούν συγκεκριμένες εκδόσεις. Συνίσταται να ανατρέχετε στην επίσημη
                    βιβλιογραφία των κατασκευαστριών εταιρειών για την πιο επίκαιρη
                    πληροφόρηση.
                  </p>
                  <ul>
                    <li>
                      <a href="https://support.google.com/chrome/answer/95647?hl=el">
                        Google Chrome: Enable and disable cookies
                      </a>
                    </li>
                    <li>
                      <a href="http://windows.microsoft.com/el-gr/windows7/block-enable-or-allow-cookies">
                        Internet Explorer: Enable and disable cookies
                      </a>
                    </li>
                    <li>
                      <a href="https://support.mozilla.org/el/kb/enable-and-disable-cookies-website-preferences">
                        Mozilla Firefox: Enable and disable cookies
                      </a>
                    </li>
                    <li>
                      <a href="http://support.apple.com/kb/ph5042">Safari: Enable and disable cookies</a>
                    </li>
                  </ul>
                  <p>
                    Για τους web browsers άλλων κατασκευαστριών εταιρειών παρακαλείσθε να
                    ανατρέξετε στη επίσημη βιβλιογραφία τους.
                  </p>
                </div>

              </>
            )}
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
