import React from "react";
import { Routes, Route } from "react-router-dom";
import BlogIndex from "pages/BlogIndex";

import StartingYourFirstSTR from "components/blogPosts/en/StartingYourFirstSTR";
import MaximizingRevenueForSTR from "components/blogPosts/en/MaximizingRevenueForSTR";
import ImportanceOfFinancialManagement from "components/blogPosts/en/ImportanceOfFinancialManagement";
import StreamliningSTROperations from "components/blogPosts/en/StreamliningSTROperations";
import UltimateGuideToSTRTaxManagement from "components/blogPosts/en/UltimateGuideToSTRTaxManagement";
import CreatingAWinningSTRListing from "components/blogPosts/en/CreatingAWinningSTRListing";
import UnderstandingKPIsForSTR from "components/blogPosts/en/UnderstandingKPIsForSTR";
import BenefitsOfBookkeepingApp from "components/blogPosts/en/BenefitsOfBookkeepingApp";
import EffectiveMarketingForSTR from "components/blogPosts/en/EffectiveMarketingForSTR";
import ManagingGuestExpectations from "components/blogPosts/en/ManagingGuestExpectations";

import KsekinontasMeToPrwtoSasKataluma from "components/blogPosts/el/KsekinontasMeToPrwtoSasKataluma";
import SimasiaOikonomikisDiaxeirisis from "components/blogPosts/el/SimasiaOikonomikisDiaxeirisis";
import DimioyrgiaEpitiximenisKatacrisisVR from "components/blogPosts/el/DimioyrgiaEpitiximenisKatacrisisVR";

const BlogRoutes = () => (
  <Routes>
    <Route path="" element={<BlogIndex />} />
    <Route path="starting-your-first-str" element={<StartingYourFirstSTR />} />
    <Route path="maximizing-revenue-for-my-str" element={<MaximizingRevenueForSTR />} />
    <Route path="importance-of-financial-management-for-my-str" element={<ImportanceOfFinancialManagement />} />
    <Route path="streamlining-str-operations-tools-and-techniques" element={<StreamliningSTROperations />} />
    <Route path="ultimate-guide-for-str-tax-management" element={<UltimateGuideToSTRTaxManagement />} />
    <Route path="how-to-create-a-winning-str-listing" element={<CreatingAWinningSTRListing />} />
    <Route path="understanding-key-performance-indicators-kpis-for-performance-success" element={<UnderstandingKPIsForSTR />} />
    <Route path="benefits-of-using-a-bookkeeping-app-for-strs" element={<BenefitsOfBookkeepingApp />} />
    <Route path="str-marketing-strategies" element={<EffectiveMarketingForSTR />} />
    <Route path="ensure-positive-guest-experience-with-these-best-practises" element={<ManagingGuestExpectations />} />
    
    <Route path="ksekinontas-me-to-proto-sas-kataluma" element={<KsekinontasMeToPrwtoSasKataluma />} />
    <Route path="simasia-tis-oinonomikis-diaxeirisis-gia-to-kataluma-sas" element={<SimasiaOikonomikisDiaxeirisis />} />
    <Route path="pos-na-dimiourgisw-mia-epituximeni-kataxorisi-vraxuxronias-misthosis" element={<DimioyrgiaEpitiximenisKatacrisisVR />} />
  </Routes>
);

export default BlogRoutes;
