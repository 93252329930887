
const posts = [
    {
      imageSrc: '/images/kyle-glenn-xY4r7y-Cllo-unsplash.jpg',
      category: "Short Term Rentals (STR)",
      date: "June 1, 2024",
      date_sys: "2024-06-01T11:05:25+00:00",
      title: "Starting Your First Short-Term Rental Property: A Beginner's Guide",
      description: "Diving into the world of short-term rentals (STR) can be both exciting and daunting. Here are some key steps to ensure your first STR property is a success.",
      url: "/en/blog/starting-your-first-str",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/jakub-zerdzicki-ykgLX_CwtDw-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 3, 2024",
      date_sys: "2024-06-03T16:01:21+00:00",
      title: "Maximizing Revenue for Your Short-Term Rental: Tips and Tricks",
      description: "Effective ways to boost your rental income.",
      url: "/en/blog/maximizing-revenue-for-my-str",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/micheile-henderson-lZ_4nPFKcV8-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 7, 2024",
      date_sys: "2024-06-07T18:57:27+00:00",
      title: "The Importance of Financial Management in Short-Term Rentals",
      description: "Why financial management is crucial for your STR business.",
      url: "/en/blog/importance-of-financial-management-for-my-str",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/thisisengineering-hOCYuLmTTnY-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 11, 2024",
      date_sys: "2024-06-11T10:34:03+00:00",
      title: "Streamlining STR Operations: Tools and Techniques",
      description: "Enhance efficiency with these tools and techniques.",
      url: "/en/blog/streamlining-str-operations-tools-and-techniques",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/scott-graham-5fNmWej4tAA-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 13, 2024",
      date_sys: "2024-06-13T17:56:47+00:00",
      title: "The Ultimate Guide to STR Tax Management",
      description: "Stay compliant and optimize your tax obligations.",
      url: "/en/blog/ultimate-guide-for-str-tax-management",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/kenny-eliason-WwrQnL0Gi1c-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 16, 2024",
      date_sys: "2024-06-16T21:02:07+00:00",
      title: "How to Create a Winning STR Listing",
      description: "Tips to attract more bookings with a compelling listing.",
      url: "/en/blog/how-to-create-a-winning-str-listing",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/myriam-jessier-eveI7MOcSmw-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 19, 2024",
      date_sys: "2024-06-19T20:09:56+00:00",
      title: "Understanding Key Performance Indicators (KPIs) for STR Success",
      description: "Essential metrics for measuring your STR’s performance",
      url: "/en/blog/understanding-key-performance-indicators-kpis-for-performance-success",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/cartist-hOGKh5qHNAE-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 22, 2024",
      date_sys: "2024-06-22T11:34:00+00:00",
      title: "The Benefits of Using a Bookkeeping App for STRs",
      description: "Streamline financial management with a bookkeeping app",
      url: "/en/blog/benefits-of-using-a-bookkeeping-app-for-strs",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/melanie-deziel-U33fHryBYBU-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 25, 2024",
      date_sys: "2024-06-25T14:01:57+00:00",
      title: "Effective Marketing Strategies for Short-Term Rentals",
      description: "Attract more bookings with these marketing strategies",
      url: "/en/blog/str-marketing-strategies",
      featured: false,
      language: "en"
    },
    {
      imageSrc: "/images/rostyslav-savchyn-_ApIH0DFLmA-unsplash.jpg",
      category: "Short Term Rentals (STR)",
      date: "June 29, 2024",
      date_sys: "2024-06-29T10:09:12+00:00",
      title: "Managing Guest Expectations: Best Practices for STR Hosts",
      description: "Ensure a positive guest experience with these best practices",
      url: "/en/blog/ensure-positive-guest-experience-with-these-best-practises",
      featured: false,
      language: "en"
    },
  
  
    {
      imageSrc: "/images/kyle-glenn-xY4r7y-Cllo-unsplash.jpg",
      category: "Βραχυχρόνιες Μισθώσεις",
      date: "17 Ιουνίου, 2024",
      date_sys: "2024-06-17T13:46:19+00:00",
      title: "Ξεκινώντας την πρώτη σας βραχυχρόνια μίσθωση: Οδηγός για νέους οικοδεσπότες",
      description: "Η είσοδος στον κόσμο των βραχυχρόνιων μισθώσεων μπορεί να είναι και συναρπαστική και τρομακτική. Εδώ είναι μερικά βασικά βήματα για να εξασφαλίσετε ότι η πρώτη σας ιδιοκτησία βραχυχρόνιας μίσθωσης θα είναι επιτυχής.",
      url: "/el/blog/ksekinontas-me-to-proto-sas-kataluma",
      featured: false,
      language: "el"
    },
    {
      imageSrc: "/images/micheile-henderson-lZ_4nPFKcV8-unsplash.jpg",
      category: "Βραχυχρόνιες Μισθώσεις",
      date: "22 Ιουνίου, 2024",
      date_sys: "2024-06-22T17:12:24+00:00",
      title: "Η Σημασία της Οικονομικής Διαχείρισης στις Βραχυχρόνιες Ενοικιάσεις",
      description: "Γιατί η οικονομική διαχείριση είναι κρίσιμη για το κατάλυμα σας.",
      url: "/el/blog/simasia-tis-oinonomikis-diaxeirisis-gia-to-kataluma-sas",
      featured: false,
      language: "el"
    },
    {
      imageSrc: "/images/krakenimages-376KN_ISplE-unsplash.jpg",
      category: "Βραχυχρόνιες Μισθώσεις",
      date: "26 Ιουνίου, 2024",
      date_sys: "2024-06-26T08:27:36+00:00",
      title: "Πώς να Δημιουργήσω μια Επιτυχημένη Καταχώρηση Καταλύματος Βραχυχρόνιας Μίσθωσης",
      description: "Συμβουλές για να προσελκύσετε περισσότερες κρατήσεις με μια ελκυστική καταχώρηση.",
      url: "/el/blog/pos-na-dimiourgisw-mia-epituximeni-kataxorisi-vraxuxronias-misthosis",
      featured: false,
      language: "el"
    },
  ];

module.exports = { posts };